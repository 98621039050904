import React, { useRef, useState } from "react";
import { Link } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react";

import { navigate } from 'gatsby';



import Search from './searchjs.js';





import "swiper/css";

import {Navigation,Autoplay } from "swiper";

export default function App(props) {

  






  return (

      <>
  <Swiper
        className='transparent'
        spaceBetween={0}

                centeredSlides={false}
   autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}


        grabCursor={true}
  


initialSlide={1}
  onSlideChange={(swiperCore) => {
            const {
              activeIndex,
              snapIndex,
              previousIndex,
              realIndex,
            } = swiperCore;

window.scrollTo(0,0)


              if (swiperCore.realIndex === 2) {    navigate('/');    };

              if (swiperCore.realIndex === 0) {    navigate('/talk');    };





        }}



        pagination={{
          clickable: true,
        }}
        modules={[Autoplay]}
      >



              <SwiperSlide data-swiper-autoplay="" className=' transparent'> </SwiperSlide>





            
             <SwiperSlide data-swiper-autoplay="999999000" className='switchToSLC white'> 




<div className='longPage '>



<Search/>






</div>

        </SwiperSlide>





























      <SwiperSlide data-swiper-autoplay="999999000" className=''> </SwiperSlide>

</Swiper>
       </> 
            
  );
}