import * as React from "react"

import Seo from "../components/seo"
import Slider from "../components/coresearch"

import {Helmet} from "react-helmet";




const mainPage = ({}) => {



  return (

<div>
    <Seo
        title='Search | E.O. Chirovici'
        
        description="Search Eugen Ovidiu Chirovici's personal website  "

        keywords='Eugen Ovidiu Chirovici, The Book of Mirrors, E.O. Chirovici, Eugen Chirovici, Bad Blood, Sleeping Dogs, Rumours, Gods Weapons and Money, Eugene Chirovici'



      />

<Helmet>

</Helmet>



<Slider/>
     





    </div>
  )

}


export default mainPage;